<template>
  <Dialog
    v-model:visible="showModal"
    :modal="true"
    icon="pi pi-external-link"
    position="center"
    :style="{ width: '60vw' }"
    @hide="$emit('close')"
    header="New Client"
  >
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="">Name</label>
          <input type="text" class="form-control" v-model="v.name.$model" :class="{ 'is-invalid': v.name.$error }" />
          <p class="invalid-feedback d-block m-0">{{ v.name.$errors[0]?.$message }}</p>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">Last Name</label>
          <input
            type="text"
            class="form-control"
            v-model="v.last_name.$model"
            :class="{ 'is-invalid': v.last_name.$error }"
          />
          <p class="invalid-feedback d-block m-0">{{ v.last_name.$errors[0]?.$message }}</p>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">Email</label>
          <input type="text" class="form-control" v-model="v.email.$model" :class="{ 'is-invalid': v.email.$error }" />
          <p class="invalid-feedback d-block m-0">{{ v.email.$errors[0]?.$message }}</p>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="">Phone</label>
          <input type="text" class="form-control" v-model="form.phone" />
        </div>
      </div>

      <fieldset class="row mt-3">
        <legend>Client Current Living</legend>
        <div class="col-12">
          <div class="form-group">
            <label for="">Address</label>
            <input
              type="text"
              class="form-control"
              v-model="v.address.$model"
              :class="{ 'is-invalid': v.address.$error }"
            />
            <p class="invalid-feedback d-block m-0">{{ v.address.$errors[0]?.$message }}</p>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">City</label>
            <input type="text" class="form-control" v-model="v.city.$model" :class="{ 'is-invalid': v.city.$error }" />
            <p class="invalid-feedback d-block m-0">{{ v.city.$errors[0]?.$message }}</p>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="">State</label>
            <input
              type="text"
              class="form-control"
              v-model="v.state.$model"
              :class="{ 'is-invalid': v.state.$error }"
            />
            <p class="invalid-feedback d-block m-0">{{ v.state.$errors[0]?.$message }}</p>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="">Country</label>
            <input
              type="text"
              class="form-control"
              v-model="v.country.$model"
              :class="{ 'is-invalid': v.country.$error }"
            />
            <p class="invalid-feedback d-block m-0">{{ v.country.$errors[0]?.$message }}</p>
          </div>
        </div>
      </fieldset>
    </div>

    <template #footer>
      <button type="button" class="btn btn-secondary" @click="$emit('close')">Close</button>
      <button type="submit" class="btn btn-primary" @click="handleSubmit">Save</button>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import { reactive } from '@vue/reactivity'

import { required, email } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import ClientService from '../../../services/client.service'

export default {
  components: { Dialog },
  props: {
    showModal: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const form = reactive({
      name: '',
      last_name: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      country: '',
    })

    //Validation
    const rules = {
      name: { required },
      last_name: { required },
      email: { required, email },
      address: { required },
      city: { required },
      state: { required },
      country: { required },
    }

    const v = useVuelidate(rules, form)

    const reset = () => {
      form.name = ''
      form.last_name = ''
      form.email = ''
      form.phone = ''
      form.address = ''
      form.city = ''
      form.state = ''
      form.country = ''
      v.value.$reset()
    }

    const handleSubmit = async () => {
      const isValid = await v.value.$validate()
      if (!isValid) return
      const client = { ...form }

      const newClient = await ClientService.createClient(client)
      reset()
      emit('save', newClient)
    }

    return { handleSubmit, form, v }
  },
}
</script>

<style lang="scss" scoped>
fieldset {
  border: 1px solid #b6b6b6;
  legend {
    font-size: 1rem;
  }
}
</style>
