<template>
  <ModalNewClient :showModal="showCreate" @close="showCreate = false" @save="addClient"></ModalNewClient>
  <div class="row w-100 m-0 text-start">
    <div class="col-12">
      <h2>Clients</h2>
    </div>
    <div class="col-12">
      <button class="btn btn-info btn-sm" @click="showCreate = true"><i class="fas fa-plus me-2"></i>New Client</button>
    </div>

    <div class="col-12 mt-4">
      <DataTable
        :value="clients"
        responsiveLayout="scroll"
        :paginator="true"
        :alwaysShowPaginator="false"
        :rows="numRows"
        removableSort
        v-model:filters="filters"
        :loading="loading"
        ref="dt"
        dataKey="id"
        editMode="row"
        v-model:editingRows="editingRows"
        @row-edit-save="onRowEditSave"
      >
        <template #header>
          <div class="row justify-content-between">
            <div class="col-3">
              <div class="d-flex align-items-center">
                <p class="text-start m-0 me-3">Show</p>
                <select class="form-select" v-model="numRows" style="width: 80px">
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <input type="text" class="form-control" placeholder="Search" v-model="filters['global'].value" />
            </div>
          </div>
        </template>
        <Column field="name" header="Name" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="last_name" header="Last Name" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="email" header="Email" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="phone" header="Phone" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="address" header="Address" :sortable="true" style="min-width: 20rem">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="city" header="City" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="state" header="State" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="country" header="Country" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column :rowEditor="true"></Column>
        <Column>
          <template #body="{ data }">
            <button class="btn" @click="deleteClient(data)"><i class="pi pi-trash"></i></button>
          </template>
        </Column>
        <template #footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="exportCSV">Export CSV</button>
          </div>
        </template>
        <template #loading> <p class="text-center">Loading records, please wait...</p> </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import ModalNewClient from './components/ModalNewClient.vue'
import { computed, ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Swal from 'sweetalert2/src/sweetalert2'

import ClientService from '../../services/client.service'
import { useDatatable } from '../../hooks/useDatatable'
import { useStore } from 'vuex'

export default {
  components: {
    DataTable,
    Column,
    ModalNewClient,
  },
  setup() {
    const store = useStore()
    const role = computed(() => store.state.selectedRole)
    const showCreate = ref(false)
    const clients = ref([])
    const { loading, filters, numRows, dt, exportCSV, editingRows } = useDatatable()

    watch(
      () => role.value,
      async () => {
        loading.value = true
        clients.value = await ClientService.fetchAll(role.value.id)
        loading.value = false
      },
      { immediate: true }
    )

    const addClient = (client) => {
      clients.value.push(client)
      showCreate.value = false
    }

    const deleteClient = (client) => {
      Swal.fire({
        title: `Are you sure to eliminate ${client.name}?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ClientService.deleteClient(client.id)
          clients.value = clients.value.filter((c) => c.id != client.id)
          Swal.fire('Deleted!', 'Client has been deleted.', 'success')
        }
      })
    }

    const onRowEditSave = async (event) => {
      const { newData } = event
      const client = { ...newData }
      const updated = await ClientService.updateClient(client.id, client)
      clients.value = clients.value.map((c) => (c.id == updated.id ? updated : c))
    }

    return {
      clients,
      showCreate,
      loading,
      filters,
      numRows,
      dt,
      exportCSV,
      editingRows,
      onRowEditSave,
      addClient,
      deleteClient,
    }
  },
}
</script>
